.lingk-dialog{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 180px;
  margin-top: -90px;
  margin-left: -150px;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  display: block;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 15px 0px, transparent 0px 0px 0px 0px;
  z-index: 2000;
  border: 1px solid rgb(201, 215, 223);
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1 !important;
  background-color: rgba(0, 0, 0, 0.75);
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.overlay-100 {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1005 !important;
  background-color: rgba(0, 0, 0, 0.75);
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
