/*
  visualizer panel on in edittrans.js
*/
.viz{
  height:100%;
  width:100%;
  background:white;
  position:absolute;
  top:0;
  left:0;
  z-index:98;
  display:block;
}
.viz-enter{
  left: 100%;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
.viz-enter-active{
  left: 0;
}
.viz-leave{
  left: 0;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
.viz-leave-active{
  left: 100%;
}
/*
  logs in monitorTransformerResult.js
*/
.logs{
  width:98%;
  max-height:350px;
  margin-left: 1%;
}
.logs-scroller-tab{
  overflow-y:auto;
}
.logs-scroller-tab::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.logs-scroller-tab::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.logs-scroller{
  overflow-y:auto;
  height: 100%;
  margin:2px;
  max-height: 350px;
  background: #cccdcf;
}
.logs-scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.logs-scroller::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.logs-enter{
  max-height: 0px;
  opacity: 0;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
.logs-enter-active{
  max-height: 350px;
  opacity: 1;
}
.logs-leave{
  max-height: 350px;
  opacity: 1;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
.logs-leave-active{
  max-height: 0px;
  opacity: 0;
}

.yaml-error-message-enter{
  opacity: 0;
}
.yaml-error-message-enter-active{
  opacity: 1;
}
.yaml-error-message-leave{
  opacity: 1;
}
.yaml-error-message-leave-active{
  opacity: 0;
}


.choose-wizard-account{
  display: inline-block;
  margin-top:22px;
  width:100%;
  vertical-align: top;
  text-align: center;
}
.choose-wizard-account-enter{
  transform: translate(100%);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.choose-wizard-account-enter-active{
  transform: translate(0);
}
.choose-wizard-account-leave{
  transform: translate(0);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.choose-wizard-account-leave-active{
  transform: translate(100%);
}

.choose-wizard-account-pics{
  position: absolute;
  left: 0px;
  text-align: center;
  margin-top: 13px;
  transition: transform 0.2s;
  width: 100%;
  display: inline-block;
  vertical-align: top;  
}
.choose-wizard-account-pics-enter{
  transform: translate(-100%);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.choose-wizard-account-pics-enter-active{
  transform: translate(0);
}
.choose-wizard-account-pics-leave{
  transform: translate(0);
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.choose-wizard-account-pics-leave-active{
  transform: translate(-100%);
}

.intro-form{
  max-height:1200px;
  overflow: hidden;
  transition: max-height 0.25s;
}
.intro-form-enter{
  max-height: 0px;
}
.intro-form-enter-active{
  max-height: 1200px;
}
.intro-form-leave{
  max-height: 1200px;
}
.intro-form-leave-active{
  max-height: 0px;
}




