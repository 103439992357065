.sffreemium-menu-icon:hover {
  background: #005fb2 !important;
}
.ellucian-menu-icon:hover {
  background: #462f89 !important;
}
.unizin-menu-icon:hover{
	background: #502e89 !important;
}


.sffreemium-elevio ._elevio_navbar{
	background: #0070d2 !important;
}
.sffreemium-elevio ._elevio_launcher button {
	background: #005fb2 !important;
}

.ellucian-elevio ._elevio_navbar{
	background: #632a74 !important;
}
.ellucian-elevio ._elevio_launcher button {
	background: #462f89 !important;
}

.unizin-elevio ._elevio_navbar{
	background: #8056c7 !important;
}
.unizin-elevio ._elevio_launcher button {
	background: #502e89 !important;
}