
figure {
  margin: 0;
}

figure.logo {
  width: 9rem;
  height: 9rem;
  background: #f5f5f5;
  border-radius: 100px;
  margin: 2rem auto;
  line-height: 9rem;
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  color: #2c0730;
  -webkit-transition: border-radius 0.5s, width 0.5s, height 0.5s, margin 0.5s, line-height 0.5s;
  -moz-transition: border-radius 0.5s, width 0.5s, height 0.5s, margin 0.5s, line-height 0.5s;
  -ms-transition: border-radius 0.5s, width 0.5s, height 0.5s, margin 0.5s, line-height 0.5s;
  -o-transition: border-radius 0.5s, width 0.5s, height 0.5s, margin 0.5s, line-height 0.5s;
  transition: border-radius 0.5s, width 0.5s, height 0.5s, margin 0.5s, line-height 0.5s;
}

figure.logo .term {
  line-height: 1em;
  font-size: 0.5em;
  margin-left: -0.25rem;
}

figure.success {
  width: 100%;
  background: #30af3b;
  padding: 1rem;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.15rem;
  color: #f5f5f5;
  opacity: 0;
  position: relative;
  top: 4rem;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -ms-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transition: opacity 0.5s 0.25s, top 1s;
  -moz-transition: opacity 0.5s 0.25s, top 1s;
  -ms-transition: opacity 0.5s 0.25s, top 1s;
  -o-transition: opacity 0.5s 0.25s, top 1s;
  transition: opacity 0.5s 0.25s, top 1s;
}

@media screen and (max-height: 599px) {
  figure.logo {
    width: 100%;
    height: 3rem;
    margin: 2rem auto 1rem;
    border-radius: 0;
    line-height: 3rem;
  }
}

.recurly-hosted-field {
  position: relative;
  height: 42px;
  width:100%;
  background: transparent;
  padding: 0.5rem;
  margin: 0 0 1rem;
  outline: none;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  box-shadow: none;
  border:1px solid #000;
  border-radius: 2px;
  color: #c2c2c2;
  -webkit-appearance: none;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.recurly-hosted-field-focus {
  border-color: #2c0730;
  color: #2c0730;
  z-index: 10;
}