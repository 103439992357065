html {
  background-color: #dcdfdf;
  height: 100%;
  width: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  background-color: #dcdfdf !important;
  font-family: "Lingk", Arial, sans-serif;
}

.apply-coupon {
  width: fit-content;
  color: #337ab7;
  font-weight: bold;
}

.test {
  height: 83vh;
}

.readonly-banner {
  /*display: inline-block;*/
  position: absolute;
  top: 46px;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  /*height:45px;*/
  pointer-events: none;
  width: calc(~"100% - 14px");
  background: #eeeeaa;
  opacity: 0.7;
  padding: 5px;
  /*padding-right:20px;*/
  z-index: 5;
  /*position:'relative';*/
}

.apply-coupon:hover {
  cursor: pointer;
}

.bread-crumb {
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s;
  transition: all 0.1s;
}

.bread-crumb:hover {
  -webkit-box-shadow: 0px 1px 0px 0px #fff;
  -moz-box-shadow: 0px 1px 0px 0px #fff;
  box-shadow: 0px 1px 0px 0px #fff;
  transform: translateY(2px);
}

.know-more:hover {
  cursor: pointer;
}

@font-face {
  font-family: "Lingk";
  src: url("fonts/Avenir-Roman-webfont.eot");
  src: url("fonts/Avenir-Roman-webfont.eot?#iefix") format("embedded-opentype"),
    url("fonts/Avenir-Roman-webfont.woff") format("woff"),
    url("fonts/Avenir-Roman-webfont.ttf") format("truetype");
}

#react-root {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

#react-root > div {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.auth0-lock.auth0-lock .auth0-lock-terms {
  background: #d1e5c8 !important;
}

.auth0-lock .auth0-lock-sign-up-terms-agreement label input[type="checkbox"] {
  outline: 2px solid #d9534f;
}

.auth0-lock
  .auth0-lock-sign-up-terms-agreement
  label
  input[type="checkbox"]:checked {
  outline: none;
}

a {
  font-weight: bold;
}

.sf-jobs-table {
  width: 1200px;
  border: 1px solid #c1c1c1;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  overflow-x: auto;
  height: 85vh;
}

.contact-header {
  font-size: 16px;
  padding: 3px;
  font-weight: bold;
}

.ReactCollapse--collapse {
  max-height: 120px;
  overflow-y: auto !important;
}

.sf-jobs {
  height: 100vh;
}

.sf-header {
  font-size: 15px;
  /* margin: auto; */
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: underline;
}

.analytics-row-name:hover {
  background: #fff;
  cursor: pointer;
}

.details-row-name:hover {
  background: #fff;
  cursor: pointer;
}

.list-divider {
  padding: 3px;
  font-size: 12px;
  color: #888;
  width: 100%;
}

.wiz-box-icon {
}

.sub-details {
  font-size: 15px;
  margin-bottom: 10px;
}

.btn {
  border-radius: 2px;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  cursor: default;
}

.coupon-button:hover {
  background: #888;
  filter: invert(100%);
}

.delete-sub-icon {
  position: absolute;
  right: 8px;
  top: 9px;
  width: 14px;
  transition: all 0.1s;
  transform: scale(1.2);
}

.data-grid-container .data-grid .cell .value-viewer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.data-grid-container .data-grid .cell.updated {
  background-color: transparent;
  transition: background-color 0ms ease;
}

textarea.data-editor:focus {
  outline: none;
}

table.data-grid {
  margin: auto;
  width: 100%;
}

.existing-org-fold {
  transition: all 0.4s;
  width: 50%;
  opacity: 1;
}

.cancel-sub:hover {
  text-decoration: underline;
  cursor: pointer;
}

.description-icon {
  transition: all 0.2s;
}

.description-icon:hover {
  transform: scale(1.2);
}

.nested-item {
  display: inline-block;
  padding: 7px;
  margin-left: 30px;
  font-size: 13px;
  color: #ddd;
}

.nested-sidebar:hover .nested-item {
  color: #fff;
}

.nested-item-small {
}

.sub-failed-banner {
  width: 100%;
  position: fixed;
  top: -2px;
  left: 0px;
  background: #f2dede;
  border: 2px solid #ebccd1;
  text-align: center;
  font-size: 20px;
  z-index: 40000;
  color: #a94442;
  font-weight: bold;
  padding: 20px;
  transition: all 0.5s;
}

.recurly-addons {
  width: 100%;
  background: #fff;
  margin: 5px;
  height: 32px;
  /*border-bottom:1px solid #999;*/
  padding: 2px;
}

.recurly-addons:last-child {
  border-bottom: 0px solid #fff;
}

.payment-header {
  text-align: left;
  width: 100%;
  padding-bottom: 0px;
  padding-left: 9px;
  padding-right: 9px;
  /*border-bottom:2px solid #222;*/
}

.payment-header-addons {
  text-align: left;
  width: 100%;
  padding-left: 9px;
  padding-right: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.signup-field {
  margin: 15px 10px 10px 20px;
  display: inline-block;
}

.signup-field select {
  width: 230px;
}

.org-link:hover {
  cursor: pointer;
}

.signup-field input {
  width: 240px;
  height: 35px;
  color: #495057;
  line-height: 30px;
  font-size: 16px;
  padding-left: 5px;
  border-color: #ced4da;
  background-color: #f8f8ff;
}

.signup-field-job {
  margin: 10px 10px 10px 20px;
  display: inline-block;
}

.signup-field-captcha {
  margin: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-field-job select {
  width: 230px;
}

.signup-field-job input {
  width: 510px;
  height: 35px;
  line-height: 30px;
  font-size: 16px;
  padding-left: 5px;
  color: #495057;
  border-color: #ced4da;
  background-color: #f8f8ff;
}

.card-env .signup-field-job input {
  width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  padding-left: 5px;
}

.signup-field-job label {
  display: block;
  text-transform: capitalize;
  font-size: 15px;
  color: #555;
}

.card-env .signup-field input {
  width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  padding-left: 5px;
}

.invoice-line-item {
  border: 1px solid #bbb;
  padding: 10px;
  margin-bottom: 20px;
}

.invoice-line-item-contents {
  border-bottom: 1px solid #555;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.invoice-line-item-contents:last-child {
  border-bottom: 0px solid #555;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.card-env {
  // border-top: 1px solid #222;
  /*border-bottom: 1px solid #222; */
  // padding-top: 20px;
  padding-bottom: 10px;
  // margin: 10px 0px 10px 0px;
}

.jss(@index) when (@index > 0) {
  .jss@{index} {
    font-size: 14px !important;
  }

  .jss(@index - 1);
}

.jss(100);

.Pagination-rowsLabel-(@index) when (@index > 0) {
  .Pagination-rowsLabel-@{index} {
    font-size: 14px !important;
  }

  .Pagination-rowsLabel-(@index - 1);
}

.Pagination-rowsLabel-(100);

.PageSizeSelector-label-(@index) when (@index > 0) {
  .PageSizeSelector-label-@{index} {
    font-size: 14px !important;
  }

  .PageSizeSelector-label-(@index - 1);
}

.PageSizeSelector-label-(100);

.recurly-hosted-field {
  width: 490px;
  margin: auto;
  border: 1px solid #aaa;
}

.recurly-hosted-field-focus {
  border: 1px solid #444;
}

.curly-hosted-field {
  font-size: 16px;
  color: #000;
  width: 230px;
  height: 36px;
  font-weight: normal;
}

.curly-hosted-field:focus {
  border: 1px solid #444;
}

.signup-field label {
  display: block;
  text-transform: capitalize;
  font-size: 15px;
  color: #555;
}

.sign-up-form {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 30px;
  margin-top: 0px;
}

.payment-phase {
  background: #fff;
  position: relative;
  border-radius: 4px;
  padding: 10px;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
  border: 1px solid #999;
  /*
-webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5); */
}

.order-summary {
  padding: 15px 20px 15px 20px;
  margin: -10px;
  margin-top: 20px;
  vertical-align: top;
  font-weight: bold;
  text-align: left;
  margin-top: -5px;
}

.tab-replacer {
  background: #bbb;
  margin-top: 43px;
  margin-left: 10px;
}

.react-to-print {
  margin: 20px;
}

.nested-sidebar:hover .nested-item-small {
  color: #fff;
}

.nested-sidebar:hover {
  cursor: pointer;
}

.nested-sidebar img {
  filter: invert(50%);
}

.nested-sidebar:hover img {
  filter: invert(90%);
}

.nested-sidebar {
  overflow: hidden;
  background: #202020;
  font-size: 11px;
  color: #ddd;
  height: 0px;
  transition: all 0.2s;
}

.template-loader {
  padding: 10px;
}

.dropdown .btn {
  border-radius: 4px;
}

.dropdown .btn.environments-dropdown {
  max-width: 205px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
  font-weight: normal;
  cursor: pointer;
  text-align: left;
}

.provider-dropdown-wrap .dropdown-menu {
  margin-bottom: 20px;
}

.dropup .btn.creds-dropdown {
  max-width: 205px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
  font-weight: normal;
  cursor: pointer;
  text-align: left;
}

.add-subtract-addon .btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.sub-create-area {
  width: 527px;
  border-radius: 4px;
  padding: 20px 20px 50px 0px;
  position: relative;
  height: 100%;
  transition: all 0.2s;
}

.calendar-sort-list {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  width: 100px;
}

.deployment-selector {
  background: #f1f1f1b5;
  transition: all 0.2s;
  border-radius: 4px;
  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
}

.deployment-selector:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.selected-deployment {
  transition: all 0.2s;
  background: rgb(240, 248, 255);
}

.calendar-sort {
  background: #ddd;
  border-radius: 2px;
  border: 1px solid #999;
  padding: 2px 5px 2px 5px;
  margin: 0px 2px 0px 2px;
  width: fit-content;
  display: inline-block;

  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.comments-area {
  transition: all 0.4s;
}

.obj-create-env {
}

.selected-deployment:hover {
  transform: scale(1.02);
  background: rgb(240, 248, 255);
}

.obj-button-envelope {
  position: absolute;
  bottom: 10;
  right: 10;
}

.objects-title {
  font-size: 17px;
}

.object-category-envelope {
  padding: 0px 10px 5px 0px;
  width: fit-content;
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 2px;
  -webkit-transition: -webkit-transform 0.1s;
  transition: all 0.1s;
  /*

-webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  */
}

/*
.object-category-envelope:hover {
-webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.3);
  transform: translateY(2px);
}
*/

.object-env-title {
  margin-left: 6px;
  font-size: 16px;
}

.subscriber-icon {
  filter: invert(80%);
  transition: all 0.2s;
}

.subscription-item:hover .subscriber-icon {
}

.dropdown .btn.environments-dropdown .caret {
  position: absolute;
  right: 14px;
  top: 13px;
}

.dropup .btn.creds-dropdown .caret {
  position: absolute;
  right: 14px;
  top: 13px;
}

.environments-dropdown .dropdown-menu > li > a {
  color: inherit;
  /*so we can change MenuItem colors*/
}

.edittrans-console-codemirror-wrap .CodeMirror {
  // height: 170px;
}

.target-item {
  width: 430px;
  position: relative;
  font-size: 14px;
  display: block;
  overflow: hidden;
  padding: 12px 0px 12px 10px;
  border-bottom: 1px solid #888;
}

.target-item:hover {
  cursor: pointer;
  background: #f5f5f5;
}

.templates-preview {
  position: relative;
  width: 70vw;
  height: auto;
  float: left;
}

.addon-section {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  padding-right: 30px;
}

.selected-target {
  background: #fff;
  font-weight: bold;
}

.selected-target:hover {
  background: #fff;
  cursor: pointer;
}

.file-upload-form {
  padding: 6px;
  background: #ccc;
}

.sub-button-envelope {
  padding-top: 10px;
  margin-right: 70px;
}

.sub-type-env {
  position: fixed;
  top: 304px;
  padding: 20px;
  height: 385px;
  width: 480px;
  margin: -170px 10px 0px 500px;
}

.sub-type-env-trigger {
  position: absolute;
  top: 0px;
  right: -440px;
}

.event-type-button {
  text-align: center;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 85px;
  padding: 5px;
  margin: 5px;
  height: 40px;
  border-radius: 2px;
  background: #eee;
  transition: all 0.2s;
}

.black-line {
  position: absolute;
  height: 30vh;
  width: 2px;
  background: #aaa;
  margin-left: -9px;
}

.event-type-button:hover {
  background: #ddd;
  cursor: pointer;
}

.event-contents {
  background: #eee;
  padding: 6px 2px 6px 2px;
}

.hovercomment {
  cursor: pointer;
  transition: all 0.2s;
}

.hovercomment:hover {
  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
}

/**********************************/
.form-control {
  width: 300px;
}

.form-control-trigger {
  width: 300px;
  margin-left: 18px;
}

.form-control-label1 {
  margin-left: 13px;
}

.form-control-label2 {
  margin-left: 24px;
}

.form-control-label3 {
  margin-left: 28px;
}

.form-control-label4 {
  margin-left: 18px;
}

.rec-wiz-picker {
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes cyborg {
  100% {
    transform: translateX(580px);
  }
}

.template-list-item {
  outline: none !important;
  box-shadow: none;
}

.clear-cats {
  margin-left: -5px;
}

.template-list {
  height: auto;
  display: inline-block;
  background: #fff;
  position: relative;
  vertical-align: top;
  width: 280px;
  margin-right: 20px;
  height: 400px;
  /* 480px */
  margin-bottom: 10px;
  overflow-y: auto;
}

.executing-cyborg {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -300px;
  width: 260px;
  height: 3px;
  border-radius: 2px;
  background: #555;
  animation-name: cyborg;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes objectCog {
  100% {
    transform: rotate(360deg);
  }
}

.webhook-item:hover .object-edit-icon {
  animation-name: objectCog;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.form-control-textarea {
  width: 500px;
  height: 70px;
}

.form-control-yaml {
  width: 90vw;
  height: 600px;
}

.form-deploy-yaml {
  width: 90vw;
  height: 800px;
}

.form-control-execution {
  width: 90vw;
  height: 800px;
}

.form-control-trans {
  width: 800px;
}

.pagination-active {
  z-index: 1;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.form-control-checkbox,
.form-control-radio {
  width: 20px;
}

.swagger-ui {
  background-color: #fff;
  margin-top: 50px;
  height: calc(100% - 60px);
  width: calc(100% - 5px);
  overflow-y: auto; 
  overflow-x: hidden;
  padding-top: 1px;
}

.swagger-ui-heading{
  padding: 0.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;   
}

.form-horizontal {
  margin-left: 30px;
  margin-top: 60px;
}

.form-horizontal-yaml {
  margin-left: 0px;
}

.form-horizontal-agree {
  margin-top: 200px;
  text-align: center;
  width: 300px;
  margin-left: 400px;
}

.form-control-agree {
  margin-bottom: 5px;
  padding: 0;
  width: 300px;
  font-size: medium;
}

.form-label-agree {
  margin-bottom: 5px;
  padding: 0;
  width: 300px;
  text-align: left;
  font-size: medium;
}

.form-text-agree {
  margin-bottom: 5px;
  padding: 0;
  width: 300px;
  text-align: left;
  font-size: smaller;
}

.form-horizontal-import {
  margin-left: 400px;
  margin-top: 60px;
}

.form-control-left {
  float: left;
  margin-bottom: 0;
  padding: 0;
}

.form-label {
  font-size: 18px;
  font-weight: bold;
}

.form-field {
  margin-left: 25px;
}

.form-panel {
  color: black;
}

/**********************************/
.boxer {
  display: table;
  border-collapse: collapse;
}

.boxer .w_100p {
  width: 100%;
}

.boxer .box-row {
  display: table-row;
}

.boxer .box {
  display: table-cell;
  text-align: left;
  vertical-align: top;
  padding: 5px 5px 5px 5px;
  border: 0px solid black;
  font-size: 12px;
}

.boxer .box-narrow {
  display: table-cell;
  text-align: left;
  vertical-align: top;
  padding: 2px 2px 2px 2px;
  border: 0px solid black;
  font-size: 11px;
}

.boxer .box.w_50p,
.boxer .box-narrow.w_50p {
  width: 50%;
}

.hidden {
  display: none;
}

.a {
  text-decoration: underline;
}

/* -- Buttons types -------------------------------- */
.btn-primary {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-raised:active,
.btn-raised.active,
.btn-raised:active:focus,
.btn-raised.active:focus {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-raised:focus {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn.btn-circle {
  padding: 0;
  border-radius: 50%;
}

/* -- Editor styles -------------------------------- */
.cm-whitespace-leading-space {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAAxJREFUGFdjYGBgAAAABAABXM3/aQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
}

/* -- editor Loading styles -------------------------------- */
.loading {
  background-color: white;
  width: 100%;
}

/*.loading img  {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  position: absolute;
  left: 50%;
  margin-left: -150px;
}*/

/* -- bootstrap tab override -------------------------------- */

.nav-tabs > li > a {
  line-height: 2px;
  font-weight: normal;
}

.recipe-create-desc {
  margin-top: 10px;
  font-size: 16px;
  padding: 20px;
  background: #404040;
  color: #fff;
  height: 180px;
}

.editor-tabs {
  position: absolute;
  bottom: 10%;
  width: 99%;
  overflow-y: scroll;
  background: #e5e5e5;
}

.last-recipe-console {
  // position: absolute;
  bottom: 0;
  // margin-left: 25px;
  transition: all 0.35s;
  -webkit-transition: all 0.35s;
  z-index: 110;
  width: 98.5%;
  max-height: 1000px;
  top: 48%;
  background: #efefef;
  overflow-y: auto;
}

.recipe-console {
  width: 90vw;
  padding-bottom: 60px;
  margin-top: 10px;
  margin-left: 58px;
  // overflow-y: scroll;
  height: 96vh;
}

.recipe-console-wizard {
  margin-left: -15px;
  transition: all 0.35s;
  -webkit-transition: all 0.35s;
  width: 100%;
  height: 300px;
}

.title-login {
  transition: all 0.2s;
}

.title-login:hover {
}

.tab-titles {
  transition: all 0.2s;
  display: inline-block;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                              supported by Chrome and Opera */
}

.hide-sidebar .tab-titles {
  display: none;
}

.sidebar-button {
  position: absolute;
  right: 8px;
  top: 24px;
  z-index: 8726;
  overflow: hidden;
  transition: all 0.2s;
}

.go-back-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.sidebar-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.sidebar-flex {
  position: relative;
  width: 100%;
}

.title-login-lingk {
  transition: all 0.14s;
}

.title-login-lingk:hover {
  cursor: default;
}

.recipe-console-buttons-wrapper {
  background: #909090;

  -webkit-box-shadow: 2px -3px 5px -1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px -3px 5px -1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px -3px 5px -1px rgba(94, 94, 94, 0.7);

  display: inline-block;
  transition: all 0.35s;
  -webkit-transition: all 0.35s;
}

.recipe-console-content-wrapper {
  background: #909090;

  // -webkit-box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);
  // -moz-box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);
  // box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);

  padding: 5px 0;
  transition: all 0.35s;
  -webkit-transition: all 0.35s;
  outline: 1px solid rgb(205, 211, 224);
}

.recipe-console-content-wrapper-wizard {
  background: #909090;

  -webkit-box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);
  -moz-box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);
  box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);

  padding: 20px 0;
  transition: all 0.35s;
  -webkit-transition: all 0.35s;
}

.recipe-event-content-wrapper {
  // background: #909090;
  outline: 1px solid rgb(205, 211, 224);

  // -webkit-box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);
  // -moz-box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);
  // box-shadow: 0px -2px 6px 0px rgba(94, 94, 94, 1);

  padding: 5px 0;
  // transition: all 0.35s;
  -webkit-transition: all 0.35s;
}

.building-recipes {
  display: inline-block;
  vertical-align: text-bottom;
  line-height: 2.5;
  color: #337ab7;
  cursor: pointer;
  font-size: 12px;
  margin-left: 20px;
  font-weight: bold;
}

.building-recipes:hover {
  text-decoration: underline;
}

.sidebarBackground {
  background: #cccccc;
}

.line-chart .tooltip {
  padding: 3px;
  border: 2px solid;
  border-radius: 4px;
  background-color: #eee;
  opacity: 0.6;
  justify-content: center;
  align-items: center;
}

#line-chart .line:nth-child(2) {
  stroke: #0079bf;
}

#line-chart .line:nth-child(3) {
  stroke: rgb(44, 209, 142);
}

#line-chart .line:nth-child(4) {
  stroke: rgb(255, 127, 14);
}

#workspace-pie-chart .arc:nth-child(2) path {
  fill: #da7474;
}

.sidenav-main {
  position: fixed;
  top: 0;
  background: #404040;
  height: 100%;
  margin-top: 0px;
  color: white;
  z-index: 1003;
  overflow-y: auto;
  overflow-x: hidden;
}

.hide-sidebar .sidenav-main {
  width: 58px;
}

.hide-sidebar .sidebar-flex {
  width: 58px;
}

.sidenav-li:before {
  position: absolute;
  content: "";
  margin-top: -13px;
  background: rgba(0, 0, 0, 0.2);
  width: 180px;
  height: 45px;
  cursor: pointer;
}

.hide-sidebar .sidenav-li:before {
  width: 58px;
}

.subscribers {
  width: auto;
  padding: 8px;
}

.subscription-item {
  width: fit-content;
  position: relative;
  background: #fff;
  font-size: 14px;
  display: block;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
  padding: 5px;
  padding-right: 30px;
  padding-left: 26px;
  transition: all 0.2s;
  border: 1px solid #999;
}

.sub-deleting {
  display: none;
}

.delete-sub-icon:hover {
  transform: scale(1.8);
  cursor: pointer;
}

.recipe-name:hover {
  cursor: pointer;
}

.image-copy {
  height: 15px;
  margin-left: 7px;
  margin-right: 5px;
}

.image-copy:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.sub-button-envelope2 {
  position: absolute;
  right: 10;
  bottom: 10;
}

.webhook-item {
  position: relative;
  height: 40px;
  width: 550px;
  border-radius: 2px;
  color: #fff;
  overflow: hidden;
  background: #444;
  margin-bottom: 6px;
  padding-top: 5px;
  padding-left: 12px;
  line-height: 31px;
  transition: all 0.1s;
}

.webhook-item:hover {
  transform: scale(1.01);
  cursor: pointer;
}

.webhook-item:hover .webhook-buttons-env {
  display: inline-block;
}

.sys-webhook-item {
  position: relative;
  height: 40px;
  width: 260px;
  border-radius: 2px;
  color: #000;
  overflow: hidden;
  background: #aaa;
  margin-bottom: 6px;
  padding-top: 5px;
  padding-left: 12px;
  line-height: 31px;
  transition: all 0.1s;
}

.sys-webhook-item:hover {
  cursor: not-allowed;
}

.webhook-buttons-env {
  float: right;
  display: none;
}

.objects-how-to {
  display: block;
  width: 500px;
  margin-top: -43px;
}

.webhookurl-how-to {
  display: "inline-block";
  float: left;
}

.object-list {
  margin-top: 20px;
  margin-bottom: 6px;
}

.parent-env {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-left: 180px;
  transition: all 0.2s;
}

.sidenav-item-carrot-img {
  filter: invert(60%);
}

.selected-sidenav-item {
  height: 45px;
  margin-bottom: 0px;
  padding: 12px 0px;
  width: 180px;
  background: #6c63ff;
  cursor: pointer;
  vertical-align: top;
}

.sidenav-item {
  height: 45px;
  margin-bottom: 0px;
  padding: 12px 0px;
  width: 180px;
  color: #fff;
  cursor: pointer;
  vertical-align: top;
}

.project-name-header {
  height: 50px;
  position: fixed;
  top: 0px;
  left: 0px;
  max-width: 80%;
  z-index: 1002;
  padding-left: 197px;
  color: #f2f2f2;
  font-size: 16px;
  padding-top: 16px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide-sidebar .project-name-header {
  padding-left: 72px;
}

.hide-sidebar .sidenav-item {
  width: 58px;
}

.hide-sidebar .parent-env {
  padding-left: 58px;
}

.sidenav-li:hover::before {
  display: none;
}

.sidenav-logo {
  padding-top: 20px;
  height: 51px;
  width: 180px;
  cursor: pointer;
  background: #444;
  /* box-shadow: rgba(0, 0, 0, 0.8) 0px -2px 6px 0px; */
  /* border-bottom: 1px solid black;  */
}

/*
.sidenav-logo:before {
  position:absolute;
  content:"";
  background:rgba(0,0,0,0.3);
  width:180px;
  height:50px;
  cursor:pointer;
  top:0;
}
*/

.hide-sidebar .sidenav-logo {
  width: 58px;
}

.hide-sidebar .sidenav-logo:before {
  width: 58px;
}

.sidenav-logo:hover::before {
  display: none;
}

.none-env {
  background: #eee;
  height: 220px;
  padding: 30px;
  padding-top: 64px;
  width: 100%;
  margin-bottom: 40px;
  margin-right: 25px;
  margin-top: -2px;
  text-align: center;
}

body.hide-elevio ._elevio_tooltip ._elevio_qmark {
  display: none;
}

#_elev_io {
  margin-top: 2px;
}

.tabby:hover {
  text-decoration: underline;
  background: #ccc;
}

.overview-tabby:hover {
  text-decoration: underline;
  background: #ccc;
}

.fold-carrot {
  filter: invert(80%);
}

.sidebar-button:hover .fold-carrot {
  filter: invert(100%);
}

.sel-tabby {
  text-decoration: underline;
  background: #dfe0e0;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  position: inherit;
}

.main-tab-content {
  padding-left: 15px;
  background: #dfe0e0;
  width: 100%;
  overflow-y: auto;
}

.tab-content-class {
  border: 1px solid #dadce0;
  margin-bottom: 20px;
  overflow-y: auto;
  height: 85%;
  margin-top: 41px;
  padding: 0px !important;
}

.main-lower-editor-pane {
  display: inline;
  overflow-y: unset;
}

.tab-panel-class {
  padding: 18px 10px 0;
  color: #202124;
  font-size: 14px;
  position: absolute;
}

.pagination > li > a,
.pagination > li > span {
  padding: 4px 9px;
  color: #404040;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #337ab7a6;
  border-color: #337ab7a6;
}

.styles-tabs {
  margin-left: 20px;
  margin-top: 50px;
  height: 100%;
}

.overview-tab-content {
  margin-left: -20px;
  padding: 12px;
  padding-left: 25px;
  background: #dfe0e0;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 70px;
}

.overview-tab-content-sub {
  margin-left: -20px;
  padding: 12px;
  padding-left: 25px;
  background: #dfe0e0;
  // overflow-y: auto;
  height: 100%;
  padding-bottom: 70px;
}

.sub-modal-content {
  padding: 12px;
  padding-left: 25px;
  padding-bottom: 20px;
}

.confirm-delete-modal {
  width: 40vw;
  min-width: 300px;
  max-width: 660px;
  padding: 40px;
}

.nav-bars:hover {
  background: #80808014;
}

div.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.show-recipe-versions {
  width: 90vw;
}

.sub-create-modal {
  width: 75%;

  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.monaco-editor .find-widget .button.toggle {
  left: -9px !important;
}

.modal-open .modal {
  // content was not visible after page size exceeds the limit
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.confirm-create-modal {
  width: fit-content;
  margin-top: 100px;
  padding: 40px;
}

.lingk-upperleft:hover {
  cursor: pointer;
}

.home-button {
  color: #ccc;
}

.lingk-upperleft:hover .home-button {
  color: #fff;
}

.template-modal {
  width: fit-content;
  margin-top: 14px;
  padding: 40px;
}

.project-box {
  margin-bottom: 16px;
  margin-right: 16px;
  height: 100%;
  width: 100%;
  float: left;
  display: block;
  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  background: white;
  padding: 12px 16px;
  cursor: pointer;
}

.project-box:hover {
  background: #f3f3f3;
  -webkit-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
}

.main-create-btn-env {
  display: inline-block;
  margin: 0px 0px 24px 10px;
  /* margin: 0px 0px 26px 8px; */
}

.analytics-comments {
  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
}

.account-box {
  position: relative;
  margin-left: -4px;
  width: 260px;
  height: 54px;
  float: left;
  display: block;
  /*border-bottom: 1px solid #555;*/
  color: white;
  padding: 8px 14px 0px 18px;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.1s;
}

.account-box:last-child {
  border-bottom: 0px solid #555;
}

.account-box:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 53px;
  cursor: pointer;
}

.account-box:hover::before {
  display: none;
}

.account-box:hover {
  font-weight: bold;
}

.al-foldout {
  float: right;
  margin-top: -28px;
  font-size: 11px;
}

.method-selector {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 400px;
  height: 333px;
  margin: 20px;
  border: 1px solid #999;
  cursor: pointer;
  background: #fff;
  transition: all 0.2s;
}

.method-selector:hover {
  color: #fff;
  transform: translateY(2px);
  background: #337ab7;
  -webkit-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.3);
  -moz-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.3);
  box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.3);
}

.recipe-create-desc {
  transition: all 0.2s;
}

.method-selector:hover .recipe-create-desc {
  background: #222;
}

.rec-wiz-main-tog {
  display: inline-block;
  margin: 10px;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
}

.wiz-circle-env {
  width: 45%;
  margin-right: 10px;
  vertical-align: top;
  display: inline-block;
}

.icon-enveloper {
  padding: 10px;
  margin-top: 20px;
}

.transformer-box {
  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  cursor: pointer;
  background: white;
  transition: transform 0.1s;
}

.transformer-box:hover {
  background: #f3f3f3;
  -webkit-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  transform: translateY(-4px);
}

.transformer-box-list {
  overflow: hidden;
  cursor: pointer;
  height: 36px;
  margin: 2px;
  background: #eee;
  -webkit-transition: -webkit-transform 0.1s;
  transition: all 0.1s;
}

.transformer-box-list:hover {
  background: #fff;
}

.appear-on-hover-text {
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
}

.min-max-commentbar:hover .appear-on-hover-text {
  visibility: visible;
  transform: translateY(40px);
}

.recipe-view-preview {
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
}

.transformer-box-list:hover .recipe-view-preview {
  transform: translateX(-50px);
}

.recipe-view-details {
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
}

.details-row-name:hover .recipe-view-details {
  transform: scale(1.2);
}

.connexp-box {
  /*
-webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  */
  background: #fff;
  -webkit-transition: -webkit-transform 0.1s;
  transition: all 0.1s;
  border-radius: 2px;
  border: 1px solid #999;
}

.connexp-box:hover {
  -webkit-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.3);
  -moz-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.3);
  box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.3);
  transform: translateY(2px);
}

.options-box {
  display: "none";
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
}

.connexp-box:hover .options-box {
  transform: translateX(-89px);
}

edit-analytics {
  -webkit-transition: -webkit-height 0.1s;
  transition: max-height 1s;
}

.api-hub {
  -webkit-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  -moz-box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  box-shadow: 2px 1px 9px 1px rgba(94, 94, 94, 0.5);
  cursor: pointer;
  background: white;
}

.api-hub:hover {
  color: #286090;
  background: #f3f3f3;
  -webkit-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
}

.menu-icon {
  float: right;
  margin-right: 20px;
  margin-top: 9px;
  width: 59px;
  cursor: pointer;
  height: 33px;
  display: block;
  border-radius: 15px;
  padding: 7px 0px 0px 12px;
}

.menu-icon:hover {
  background: #465c3a;
}

.menu-dropdown {
  -webkit-box-shadow: 2px 2px 6px 0px rgba(94, 94, 94, 0.9);
  -moz-box-shadow: 2px 2px 6px 0px rgba(94, 94, 94, 0.9);
  box-shadow: 2px 2px 6px 0px rgba(94, 94, 94, 0.9);
}

.recipe-batch-stream {
  position: absolute;
  width: 31px;
  top: -2px !important;
  right: -2px !important;
  height: 145px;
  padding-top: 5px;
  padding-right: 2px;
  text-align: center;
}

.menu-dropdown a:hover > div {
  background: #eee;
}

.recipe-intg:hover {
  background: #dfe0e0;
}

.database-api-buttons {
  font-size: 10px;
  color: white;
  vertical-align: middle;
  font-weight: bold;
}

.database-api-buttons > div {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  min-width: 34px;
}

.built-card-area {
  position: relative;
  display: inline-block;
  width: 155px;
  height: 155px;
  margin: 0 1rem 2.5rem 1rem;
  vertical-align: top;
  transition: all 0.5s;
}

.hover-wizard-row {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #000;
  width: 220px;
  height: 155px;
}

.int-desc {
  opacity: 0;
  display: inline-block;
  transition: all 0.6s;
}

.built-card-area:hover .int-desc {
  opacity: 1;
}

.built-card-area:hover {
  height: 210px;
}

.built-card {
  position: relative;
  display: block;
  background: white;
  border: 1px solid #bbb;
  width: 155px;
  height: 155px;
  color: #222;
  border-radius: 2px;
  font-size: 1rem;
  cursor: pointer;
  background: #f7f7f7;
  padding-top: 10px;
  transition: all 0.5s;
  /*
-webkit-box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);
-moz-box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);
box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);
*/
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.built-card:hover {
  text-decoration: none;
  color: black;
  background: #fff;

  -webkit-box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.7);
}

.wizard-step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding-top: 10px;
  text-align: center;
  margin: 10px;
  display: inline-block;
}

.wizard-schema-field {
  cursor: pointer;
}

.wizard-schema-field:hover {
  background: #dfe0e0;
}

.sidebar-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.sidebar:hover .sidebar-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.zip-sub-record label {
  cursor: pointer;
  font-weight: normal;
}

.zip-sub-record:hover label {
  text-decoration: underline;
}

.zip-sub-scroll-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.zip-sub-scroll-box::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.search-form-control {
  background: #eee;
  width: 150px;
  margin: 0.9rem 0;
  border-radius: 0;
  height: 30px;
  font-size: 12px;
  padding: 4px 10px 0 10px;
}

.sort-form-control {
  background: #eee;
  width: 28px;
  margin-bottom: 1px;
  border-radius: 0;
  height: 30px;
  font-size: 12px;
  padding: 0px 9px;
}

.sort-form-control:hover {
  background: #fafafa;
}

.search-form-control:hover {
  background: #fafafa;
}

.lineSelectText {
  font-weight: bold;
}

.lineSelectBackground {
  background-color: lightblue;
}

.run-transformer-result {
  margin-bottom: 1px;
  position: relative;
  background: white;
  margin-right: 10px;
  overflow: hidden;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 6px 8px 5px 8px;
  border-bottom: 1px solid #dadce0;
}

.pagination {
  margin: unset;
}

.MuiTableCell-root {
  font-size: unset !important;
  padding: 8.9px !important;
}

.MuiButton-root {
  font-size: 1rem !important;
}

.MuiSelect-icon {
  top: unset !important;
}

[class*="Pager-pager"] {
  padding: 0px !important;
}

.selected-transformer-result {
  margin-bottom: 1px;
  position: relative;
  margin-right: 10px;
  overflow: hidden;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 6px 8px 5px 8px;
  cursor: pointer;
  border-bottom: 1px solid #cccdcf;
}

.selected-transformer-result:hover {
  background: #fafafa;
}

.selected-transformer-result-logs {
  overflow: hidden;
  margin-bottom: 1px;
  background: #fefefe;
  font-size: 14px;
  width: 100%;
  padding: 6px 8px 5px 8px;
}

/*here*/

/*! ========================================================================
* Bootstrap Toggle: bootstrap2-toggle.css v2.2.0
* http://www.bootstraptoggle.com
* ========================================================================
* Copyright 2014 Min Hur, The New York Times Company
* Licensed under MIT
* ======================================================================== */
label.checkbox .toggle,
label.checkbox.inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}

.toggle {
  min-width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  width: 0px;
  border-width: 0 1px;
}

.toggle-handle.btn-mini {
  top: -1px;
}

.toggle.btn {
  min-width: 30px;
}

.toggle-on.btn {
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.toggle-off.btn {
  padding-left: 24px;
  display: flex;
  align-items: center;
}

.toggle.btn-large {
  min-width: 40px;
}

.toggle-on.btn-large {
  padding-right: 35px;
}

.toggle-off.btn-large {
  padding-left: 35px;
}

.toggle.btn-small {
  min-width: 25px;
}

.toggle-on.btn-small {
  padding-right: 20px;
}

.toggle-off.btn-small {
  padding-left: 20px;
}

.toggle.btn-mini {
  min-width: 20px;
}

.toggle-on.btn-mini {
  padding-right: 12px;
}

.toggle-off.btn-mini {
  padding-left: 12px;
}

.slow .toggle-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s;
}

.fast .toggle-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s;
}

.quick .toggle-group {
  transition: none;
  -webkit-transition: none;
}

table.light > tbody > tr {
  background-color: white !important;
}

table.light > tbody > tr:hover {
  cursor: pointer;
  background-color: #f9f9f9 !important;
}

.webhook-dropdown-menu-wrapper {
  padding-left: 20px;
}

.webhook-dropdown-menu-wrapper .dropdown-menu {
  max-height: 273px;
  overflow-y: auto;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 2px 3px 0px;
  z-index: 9990;
  min-width: 260px;
  margin-top: 4px;
}

.sidebar .dropdown-toggle {
  border-radius: 0px;
  margin-bottom: 2px;
}

.banner-admin-form {
  border: 1px solid rgb(204, 205, 207);
  width: 725px;
  border-top: none;
}

.banner-admin-form-inputs {
  width: 42%;
  display: inline-block;
  vertical-align: top;
  padding: 20px;
}

.banner-admin-form-inputs .slds-form-element {
  margin-top: 8px;
}

.banner-admin-form-textarea {
  width: 58%;
  display: inline-block;
  padding: 20px 20px 20px 0;
  height: 100%;
  text-align: left;
}

.banner-admin-form-message {
  border: 1px solid #d8dde6;
  border-radius: 5px;
  background: #f4f6f9;
  padding: 14px 10px;
  white-space: pre-wrap;
}

.banner-admin-form-collapse {
  width: 725px;
  height: 31px;
  border: 1px solid rgb(204, 205, 207);
  transition: all 0.5s;
  cursor: pointer;
  text-align: center;
  padding-top: 6px;
  font-weight: bold;
  color: #54698d;
}

.banner-admin-form-collapse:hover {
  background: #f4f6f9;
  color: #16325c;
}

.mapping-table-header {
  color: #54698d;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  padding: 8px 16px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
}

.ajax-loader {
  position: absolute;
  top: 220px;
  left: 610px;
}

.view-recipe-in-wizard {
  color: #54698d;
  margin-bottom: 3px;
  cursor: pointer;
  width: 88px;
  display: inline-block;
}

.view-recipe-in-wizard:hover {
  text-decoration: underline;
  color: black;
}

.wizard-type-dropdown {
  display: inline-block;
}

.wizard-type-dropdown .dropdown-menu {
  width: 242px;
}

// Auth 0 customization

@media (min-width: 481px) {
  div.auth0-lock.auth0-lock {
    div.auth0-lock-header {
      height: auto;
    }

    div.auth0-lock-header-bg {
      background-color: #6c63ff;
    }

    img.auth0-lock-header-logo {
      margin: 0;
      height: auto;
    }

    div.auth0-lock-header-bg.auth0-lock-blur-support
      div.auth0-lock-header-bg-blur {
      display: none;
    }

    span.auth0-lock-close-button,
    span.auth0-lock-back-button {
      top: 5px;
      right: 5px;
    }
  }
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.environments-header {
  width: 989px;
  position: relative;
}

.leave-single-environment {
  width: 120px;
  margin-top: 50px;
  position: fixed;
  height: 36px;
  left: 70%;
}

.leave-single-environment:hover {
  fill: black;
  transform: scale(1.2, 1.2);
}

.leave-create-new-environment {
  width: 12px;
  height: 12px;
  stroke: grey;
  cursor: pointer;
  position: absolute;
  right: 2px;
  bottom: 20px;
  transition: all 0.2s;
}

.leave-create-new-environment:hover {
  stroke: black;
  transform: scale(1.2, 1.2);
}

.env-bar-dragon {
  position: relative;
}

.react-draggable-dragging.env-bar-dragon {
  z-index: 9999 !important;
}

.react-draggable-dragging .env-bar {
  box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.3);
}

.env-drag-handle {
  display: none;
}

.env-bar-dragon:hover .env-drag-handle {
  position: absolute;
  left: 8px;
  top: 4px;
  cursor: pointer;
  z-index: 99;
  height: 24px;
  display: inline-block;
}

.env-drag-handle svg {
  fill: #ddd;
}

.env-drag-handle:hover svg {
  fill: white;
  transform: scale(1.1, 1.1);
}

.env-bar-wrap {
  transition: all 0.2s;
  border-radius: 15px;
}

.env-bar {
  cursor: pointer;
  height: 95px;
  border-radius: 14px;
  background: #337ab7;
  border: 1px solid black;
  display: inline-block;
  transition: all 0.2s;
  position: relative;
  padding-left: 21px;
  width: 100%;
}

.env-bar:hover .env-bar-specs {
  background: #286090;
  display: inline-block;
}

.env-bar:hover {
  background: #286090;
}

.env-bar-specs {
  display: none;
}

.start-job {
  display: none;
}

.dropdown-button-wrap:hover .start-job {
  display: grid;
  -webkit-box-shadow: 2px 2px 6px 0px rgba(94, 94, 94, 0.9);
  -moz-box-shadow: 2px 2px 6px 0px rgba(94, 94, 94, 0.9);
  box-shadow: 2px 2px 6px 0px rgba(94, 94, 94, 0.9);
  width: 109px;
  background: white;
  height: 60px;
  padding: 5px;
}

.env-bar-color {
  height: 18px;
  width: 18px;
  border: 1px solid white;
  display: inline-block;
  margin: 6px 12px;
  vertical-align: top;
  border-radius: 1px;
}

.env-bar-title {
  color: white;
  font-size: 15px;
  padding: 5px 16px 3px 0px;
  display: inline-block;
}

.env-bar-edit-name-wrap {
  display: inline-block;
}

.env-bar-edit-name {
  fill: white;
  height: 14px;
  width: 14px;
  margin-bottom: -2px;
  cursor: pointer;
}

.env-bar-edit-name:hover {
  transform: scale(1.2, 1.2);
  fill: #21f27a;
}

.leave-env-bar-edit-name {
  stroke: white;
  height: 14px;
  width: 14px;
  margin-bottom: -2px;
  cursor: pointer;
  margin-left: 8px;
}

.leave-env-bar-edit-name:hover {
  transform: scale(1.2, 1.2);
  stroke: #21f27a;
}

.env-bar-spinner {
  display: inline-block;
  margin-left: 8px;
}

.env-bar-spinner img {
  height: 8px;
  margin-bottom: 3px;
}

.env-bar-delete {
  position: absolute;
  left: 446px;
  border-radius: 40%;
  top: 2px;
  cursor: pointer;
}

.env-bar-delete:hover svg {
  transform: scale(1.2, 1.2);
  stroke: #f46964;
}

.env-bar-delete svg {
  height: 18px;
  width: 18px;
  margin: 5px 10px 0 6px;
  stroke: white;
}

.env-content {
  opacity: 1;
}

.env-content-enter {
  opacity: 0;
}

.env-content-enter-active {
  transition: opacity 0.25s;
  opacity: 1;
}

.env-content-leave {
  opacity: 1;
}

.env-content-leave-active {
  opacity: 0;
}

.env-creds {
  overflow: hidden;
  transition: max-height 0.25s linear;
  margin-left: 0px;
  height: 88%;
}

.env-creds-enter {
  max-height: 0px;
}

.env-creds-enter-active {
  max-height: 333px;
}

.env-creds-leave {
  max-height: 333px;
}

.env-creds-leave-active {
  max-height: 0px;
}

.env-section {
  border: 1px solid #d7d7d7;
  width: 695px;
  display: inline-block;
  line-height: 1.4;
  border-radius: 8px;
  margin-bottom: 18px;
  background: #fcfcff;
  user-select: none;
  position: relative;
  z-index: 6;
  margin-left: 15px;
  box-shadow: 2px 1px 1px 0px #dddddd;
  overflow: hidden;
}

.env-section:hover {
  box-shadow: 2px 1px 1px 0px #dddddd;
}

.env-bar-wrap .env-section {
  width: 645px !important;
}

.integration-box:hover {
}

.env-section-header {
  position: relative;
  background: #fcfcff;
  border-bottom: 1px solid #d7d7d7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 44px;
  z-index: 6;
  cursor: pointer;
}

.env-section-header:hover {
  background: #f9f9ff;
}

.env-section-icon {
  position: absolute;
  left: 15px;
  top: 10px;
}

.env-section-header-title {
  position: absolute;
  left: 48px;
  top: 11px;
  font-size: 16px;
  font-weight: bold;
}

.env-section-body {
  padding: 12px 28px 7px 28px;
  background: #ffffff;
  margin-bottom: 15px;
  margin-right: 25px;
  width: 100%;
  margin-top: 10px;
  height: 96%;
  overflow: auto;
  left: 29%;
  position: relative;
}

.env-section-body-flat-file {
  padding: 12px 28px 7px 28px;
  background: #ffffff;
  margin-bottom: 15px;
  margin-right: 25px;
  width: 100%;
  margin-top: 10px;
  height: 96%;
  overflow: auto;
}

.env-metadata {
  background: #f9f9ff;
  border-left: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding: 2px 20px;
  font-size: 14px;
  width: 170px;
}

.env-metadata:hover {
  background: white;
  text-decoration: underline;
}

.env-inspect-eye {
  margin-left: 7px;
  margin-top: 7px;
  fill: #555;
}

.env-metadata:hover .env-inspect-eye {
  fill: #111;
}

.notifyStyle {
  height: 16px;
  width: 16px;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  // margin-right: 6px;
  font-size: 14px;
  margin-top: 10px;
  line-height: 16px;
}

.PopupExclamation {
  margin-left: 6px;
  margin-top: 1px;
  display: inline-block;
}

.notifyStylePopWindow {
  height: 16px;
  width: 16px;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
  font-size: 14px;
  margin-top: 2px;
  line-height: 16px;
}

.env-dropdown-color-box {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  margin-left: -10px;
  margin-right: 8px;
  border-radius: 2px;
}

.env-creds-json-codemirror {
  border: 1px solid #cacaca;
  height: 238px;
  overflow-y: scroll;
}

.env-creds-json-codemirror .CodeMirror {
  height: 236px;
}

.execute-wizard-step {
  border-bottom: 1px solid #ddd;
  position: relative;
  height: 33px;
}

.execute-wizard-step img {
  height: 32px;
}

.execute-wizard-step span {
  position: absolute;
  left: 34px;
  top: 8px;
  color: #54698d;
}

.ongoing-recipe-step {
  border-bottom: 1px solid #ddd;
  position: relative;
  height: 33px;
  background: white;
}

.ongoing-recipe-step img {
  height: 32px;
}

.ongoing-recipe-step-text {
  position: absolute;
  right: 12px;
  left: 34px;
  top: 8px;
  color: #54698d;
}

.ongoing-recipe-step-statement {
  position: absolute;
  right: 35px;
  left: 0px;
  left: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.env-bar-dropdown-wrap {
  margin: 0px 16px 24px 16px;
}

.env-bar-dropdown-wrap ul {
  margin-bottom: 30px;
}

.resources-section {
  width: 100%;
  max-width: 900px;
  margin: 32px auto;
}

.resources-tile-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.resources-tile {
  width: 200px;
  height: 150px;
  background: white;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid grey;
  color: black;
}

.resources-tile:hover {
  -webkit-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px 2px 9px 1px rgba(94, 94, 94, 0.7);
}

.users-sidebar {
  width: 33%;
  max-width: 300px;
  display: inline-block;
  vertical-align: top;
  margin-top: 32px;
}

.users-sidebar-title {
  font-weight: bold;
  font-size: 22px;
}

.user-tenant-tabs {
  margin-top: 16px;
}

.user-tenant-tab {
  padding: 16px;
  font-size: 16px;
  max-width: 268px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.user-tenant-tab-selected {
  font-weight: bold;
  color: #6c63ff;
}

.user-tenant-tab-selected:after {
  position: absolute;
  left: 0;
  height: 54px;
  bottom: 0px;
  content: "";
  width: 4px;
  background: #6c63ff;
}

user-tenant-option-tab-selected {
}

.user-tenant-tab:hover {
  background: #eee;
}

.user-project-tabs {
  margin-top: 12px;
  border: #000;
  border-width: 1px;
}

.user-project-tab {
  padding: 16px;
  font-size: 15px;
  max-width: 268px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.user-project-tab-selected {
  font-weight: bold;
  color: #6c63ff;
}

.user-project-tab-selected:after {
  position: absolute;
  left: 0;
  height: 54px;
  bottom: 0;
  content: "";
  width: 4px;
  background: #6c63ff;
}

.user-project-tab:hover {
  background: #6c63ff;
  color: #eee;
}

user-tenant-option-tabs {
  margin-top: 16px;
}

.tool-tip {
  max-width: 400px;
  line-height: normal;
}

.tool-tip-title {
  font-size: 14px;
  font-weight: bold;
  text-align: justify;
}

.users-list-wrap {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  max-width: 900px;
}

.users-list {
  margin: 10px;
  margin-top: 0px;
  padding: 24px;
  background: #eee;
}

.subscription-list {
  margin: 10px;
  margin-top: 0px;
  padding: 24px;
  padding-bottom: 0px;
  background: #eee;
}

.threashold-list {
  margin: 10px;
  margin-top: 0px;
  min-height: 100px;
  // height: 550px;
  padding: 5px 20px;
  padding-bottom: 0px;
  background: #eee;
}

.user-picker-table-wrap {
  background: #eee;
  max-width: "100%";
  position: relative;
}

.user-picker-table-wrap td {
  padding: 4px 8px !important;
}

.recipe-table-wrap {
  background: #eee;
  max-width: "90%";
  position: relative;
  margin-right: 100;
}

.recipe-table-wrap td {
  padding: 4px 8px !important;
}

.helpers-toggle {
  text-align: center;
  width: fit-content;
  // margin-left: 10px;
}

.helpers-toggle .react-toggle-thumb {
  background: #777;
  border-color: #777;
}

.helpers-toggle-color.react-toggle--checked .react-toggle-track {
  background: #515151;
}

.helpers-toggle-color.react-toggle--checked .react-toggle-thumb {
  left: 38px;
  border-color: #555;
}

.helpers-toggle .react-toggle-track {
  width: 59px;
}

.helpers-toggle .toggle-label {
  color: #ddd;
  display: inline-block;
}

.toggle-label {
  color: white;
  display: inline-block;
}

.toggle-label-admin {
  margin-left: 0px;
  font-size: 12px;
  margin-top: 5px;
}

.toggle-label-user {
  margin-left: -19px;
  font-size: 14px;
  margin-top: 5px;
}

.toggle-label-show {
  margin-left: -13px;
  font-size: 12px;
  margin-top: 5px;
}

.toggle-label-hide {
  margin-left: 0px;
  font-size: 12px;
  margin-top: 5px;
}

.invite-users-content-wrap::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.invite-users-content-wrap::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.uninvited-tenant-user {
  margin-bottom: 2px;
}

.uninvited-tenant-user-add {
  border: 1px solid #bebebe;
  border-right: none;
  display: inline-block;
  width: 21px;
  height: 21px;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.uninvited-tenant-user-add:hover {
  background: #00853e;
  color: white;
}

.uninvited-tenant-user-name {
  border: 1px solid #bebebe;
  display: inline-block;
  vertical-align: top;
  height: 21px;
  width: 90%;
  padding-left: 7px;
}

.invited-tenant-user {
  margin-bottom: 2px;
  line-height: 28px;
}

.invited-tenant-user-remove {
  border: 1px solid #00853e;
  border-right: none;
  display: inline-block;
  width: 21px;
  height: 28px;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  line-height: 25px;
}

.invited-tenant-user-remove:hover {
  background: #d9534f;
  color: white;
}

.invited-tenant-user-content {
  border: 1px solid #00853e;
  display: inline-block;
  vertical-align: top;
  height: 28px;
  width: 90%;
  padding-left: 7px;
  background: #e5f6e4;
  position: relative;
}

.invited-tenant-user-admin-toggle {
  position: absolute;
  right: 3px;
  top: 1px;
}

.workspace-monitor-result {
  border-bottom: 1px solid #ddd;
  height: 23px;
  line-height: 23px;
  width: 100%;
  cursor: pointer;
}

.workspace-monitor-result:hover {
  background: #ddd;
}

.files-header {
  // position: absolute;
  // top: 59px;
  // left: 70px;
  // font-size: 14px;
  /* top: 59px; */
  /* left: 70px; */
  /* font-size: 14px; */
  /* align-items: center; */
  margin: 9px;
  /* margin-top: 13px; */
  display: inline-block;
}

.more-option-connector-cell {
  display: none;
}

.connector-cell:hover {
  background: #d2cece;
}

.recipe-edit-cell:hover {
  background: #d2cece;
}

.connector-cell:hover .more-option-connector-cell {
  background: #d2cece;
  display: inline;
}

.recipe-settings {
  position: absolute;
  max-width: 192px;
  max-height: 271px;
  left: 251px;
  top: 162px;
}

.save-recipe a {
  border: unset;
  box-shadow: unset !important;
  transition: none;
  -webkit-box-shadow: unset !important;
  width: 10px !important;
  padding: unset !important;
}

.testing-menu {
  max-height: 400px;
}

// .dropdown-menu {
//   max-height: 400px;
//   overflow-y: auto;
//   left: unset;
//   right: 0;
// }
.show-diff {
  left: 215%;
}

.show-diff2 {
  left: 215%;
}

.save-recipe {
  border: unset;
  box-shadow: unset !important;
  transition: none;
  -webkit-box-shadow: unset !important;
  width: 10px !important;
  padding: unset !important;
}

.recipe-edit-cell {
  height: 40px;
  padding: 10px 16px;
  border: 1px solid #dfe0e0;
  font-size: 14px;
}

.time-filter-cell {
  height: 40px;
  padding: 10px 16px;
  border: 1px solid #dfe0e0;
  font-size: 14px;
}

.recommended-activity-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 850px;
  display: inline-block;
  vertical-align: top;
}

.recommended-activity {
  background: white;
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 12px;
  padding: 20px;
  height: 137px;
  vertical-align: top;
  /*box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);*/
  border: 1px solid #999;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  width: 195px;
  font-weight: bold;
  text-align: center;
  transition: all 0.1s;
}

.recommended-activity p {
  width: 80%;
  margin-left: 10%;
}

.recommended-activity:hover {
  box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.3);
  transform: translateY(2px);
}

.recommended-activity-arrow {
  background: #aaaaaa;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 100%;
  padding: 10px;
  margin-top: 17px;
}

.recommended-activity-scroll:hover {
  background: grey;
}

.recommended-activity-scroll svg {
  height: 100%;
}

.recipe-activity-icon {
  height: 45px;
  padding-left: 28px;
}

.recipe-activity-icon div {
  height: 7px;
  margin-bottom: 4px;
  background: lightgray;
  width: 100px;
  position: relative;
}

.recipe-activity-icon div:before {
  content: "";
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: white;
  box-shadow: 0 0 5px 3px white;
  transform: skewX(-20deg);
  transition: all 0.35s ease-out;
}

.undo-redo-recipe:hover {
  transition: opacity 1s ease-out;
  height: 23px;
}

.recommended-activity:hover .recipe-activity-icon div:before {
  opacity: 1;
  left: 120%;
}

.edition-info-circle {
  display: inline-block;
  background: white;
  box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.3);
  font-weight: bold;
  text-align: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
  border-radius: 100%;
  vertical-align: top;
  margin-left: 6px;
  margin-top: 24px;
  padding-top: 4px;
}

.edition-info-circle:hover {
  box-shadow: 1px 1px 7px 1px rgba(94, 94, 94, 0.7);
}

.delete-cred-bar {
  display: inline-block;
  vertical-align: top;
  margin-top: 11px;
  margin-left: 7px;
  height: 22px;
  width: 22px;
  stroke: grey;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-cred-bar:hover {
  stroke: black;
  transform: scale(1.2, 1.2);
}

.delete-billing {
  display: inline-block;
  vertical-align: top;
  height: 22px;
  width: 22px;
  stroke: grey;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-billing:hover {
  stroke: black;
  transform: scale(1.2, 1.2);
}

.add-ws {
  display: inline-block;
  vertical-align: top;
  margin-top: 14px;
  margin-left: 66px;
  height: 22px;
  width: 22px;
  stroke: grey;
  fill: grey;
  cursor: pointer;
  transition: all 0.1s;
}

.add-ws:hover {
  transform: scale(1.1);
  stroke: #fff;
  fill: #fff;
}

.plus-addon {
  display: inline-block;
  height: 18px;
  width: 18px;
  stroke: grey;
  fill: grey;
  cursor: pointer;
  transition: all 0.1s;
}

.plus-addon:hover {
  transform: scale(1.1);
  stroke: #000;
  fill: #000;
}

.delete-recipe-designer-bubble {
  height: 22px;
  width: 22px;
  stroke: grey;
  cursor: pointer;
  transition: transform 0.2s;
}

.delete-recipe-designer-bubble:hover {
  stroke: black;
  transform: scale(1.2, 1.2);
}

.exp-details-tab {
  transition: all 0.15s;
}

.exp-details-tab:hover {
  text-decoration: underline;
}

.selected-exp-details-tab {
  transition: all 0.15s;
  transform: translateY(-14px);
  text-decoration: underline;
}

.delete-experience {
  transition: all 0.2s;
}

.delete-experience:hover {
  transform: scale(1.2, 1.2);
  cursor: pointer;
}

.edit-pencil {
  margin-right: 4px;
  transition: all 0.2s;
}

.edit-pencil:hover {
  transform: scale(1.2, 1.2);
  cursor: pointer;
}

.user-pic-gear-icon {
  position: absolute;
  opacity: 0.6;
  cursor: pointer;
  padding-top: 4px;
  margin-left: 19px;
  margin-top: 11px;
}

.user-pic:hover .user-pic-gear-icon {
  opacity: 0.8;
}

.show-label-or-api-names {
  font-weight: bold;
  cursor: pointer;
}

.show-label-or-api-names:hover {
  text-decoration: underline;
}

.email-monitoring {
  margin-top: 35px;
}

.email-monitoring-title {
  padding: 12px 9px 0 12px;
  font-size: 16px;
}

.remove-email-monitoring-address {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-top: 10px;
  height: 12px;
  width: 12px;
  stroke: grey;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-email-monitoring-address:hover {
  stroke: black;
  transform: scale(1.2, 1.2);
}

.remove-email-monitoring-address:hover + span {
  color: grey;
}

.email-monitoring-address {
  max-width: 220px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logs-printer-horizontal-scroller {
  overflow-x: scroll;
}

.logs-printer-table {
  margin-bottom: 0px;
  background: rgb(250, 250, 250);
}

.span-as-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.span-as-link {
  color: #337ab7;
  font-size: 14px;
  font-weight: bold;
}

.gear-img:hover {
  cursor: pointer;
}

.logs-printer-horizontal-scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 0.6em;
}

.logs-printer-horizontal-scroller::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.wiz-source-chooser {
  position: relative;
  background: white;
  width: 115px;
  height: 115px;
  border-radius: 100%;
  color: #686868;
  font-size: 1rem;
  cursor: pointer;
  padding-top: 1px;
  -webkit-box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);
  -moz-box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);
  box-shadow: 1px 2px 4px 1px rgba(94, 94, 94, 0.4);
  overflow: hidden;
  vertical-align: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wiz-source-chooser:hover {
  text-decoration: none;
  color: black;
  -webkit-box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.7);
  -moz-box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.7);
  box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.7);
}

.check-metadata-error {
  background: rgb(234, 90, 90);
  padding: 4px 9px;
  font-weight: bold;
  margin: 12px 12px 0px;
  color: white;
  max-height: 102px;
  overflow: hidden;
}

.close-check-metadata-error {
  color: white;
  float: right;
  cursor: pointer;
  padding-top: 3px;
  height: 14px;
}

.close-check-metadata-error-x {
  fill: white;
  stroke: white;
}

.close-check-metadata-error-x:hover {
  transition: all 0.35s;
  transform: scale(1.2, 1.2);
}

.history-box-wrap {
  display: inline-block;
  float: right;
  position: relative;
  height: 42px;
  padding-top: 9px;
  width: 42px;
  margin-right: 36px;
}

.history-box-box {
  position: absolute;
  background: white;
  width: 220px;
  height: 100px;
  top: 36px;
  right: -20px;
  z-index: 110;
  box-shadow: rgba(94, 94, 94, 0.4) 1px 2px 4px 1px;
  display: none;
}

.history-box-wrap:hover .history-box-box {
  display: block;
}

.history-box-box:hover {
  display: block;
}

.delete-mapper-row {
  stroke: #a00;
  height: 14px;
  width: 14px;
  margin-bottom: -2px;
  cursor: pointer;
  margin-left: 8px;
}

.delete-mapper-row:hover {
  transform: scale(1.2, 1.2);
  stroke: #d43f3a;
}

.fix-execution {
  position: absolute;
  width: 100%;
  z-index: 102;
  background: white;
}

.open-in-new {
  height: 24px;
  margin-top: -23px;
  margin-left: 98%;
}

.mapper-edit-name-wrap {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 12px;
}

.mapper-edit-name {
  fill: black;
  height: 14px;
  width: 14px;
  margin-bottom: -2px;
  cursor: pointer;
}

.mapper-edit-name:hover {
  transform: scale(1.2, 1.2);
  fill: #21f27a;
}

.map-drag-handle {
  opacity: 0.65;
  cursor: pointer;
  height: 27px;
  padding-top: 6px;
}

.map-drag-handle:hover {
  opacity: 1;
  transform: scale(1.2, 1.2);
}

.recipe-designer-section {
  border: 1px solid #c6c6c6;
  background: #f3f3f3;
  border-radius: 6px;
  padding-left: 10px;
  height: 36px;
  margin: 10px 8px 0px;
  line-height: 36px;
  cursor: pointer;
}

.recipe-designer-section:hover {
  border: 1px solid #aaaaaa;
  background: #f5f5f5;
}

.recipe-designer-statement {
  overflow: hidden;
}

.recipe-designer-statement .CodeMirror {
  height: auto;
}

.recipe-designer-json-codemirror .CodeMirror {
  height: auto;
}

.provider-dropdown-menu-wrapper .dropdown-menu {
  max-height: 273px;
  overflow-y: auto;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 2px 3px 0px;
  z-index: 9990;
  min-width: 260px;
  margin-top: 4px;
}

.custom-elevio-category::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.custom-elevio-category:hover::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
}

.custom-elevio-category ._elevio_articlelink div {
  color: #008e8e !important;
}

.custom-elevio-category div:hover {
  color: #006969 !important;
}

.custom-elevio-category ._nr4k9 {
  display: none !important;
}

.recipe-editor-tabs .nav-tabs > li > a {
  height: 55px;
  line-height: 37px;
  font-weight: bold;
}

.recipe-editor-tabs {
  height: 100%;
}

.recipe-editor-tabs .tab-content {
  height: calc(~"100% - 55px");
}

.file-manager-table {
  user-select: text;
}

.labelz-small {
  color: #54698d;
  font-size: 11px;
  margin: 0;
}

.labelTZ {
  font-size: small;
  margin: 0;
  color: #54698d;
}

.copy-svg {
  margin-left: 5px;
  margin-top: 2px;
  display: inline-block;
  vertical-align: top;
  fill: grey;
  cursor: pointer;
}

.copy-svg:hover {
  fill: black;
}

.mover {
  fill: grey;
  cursor: pointer;
  transition: all 0.1s;
}

.mover:hover {
  fill: black;
  transform: scale(1.2);
}

.provider-association-dropdown-wrap .caret {
  position: absolute;
  right: 14px;
  top: 13px;
}

.provider-association-dropdown-wrap ul {
  margin-bottom: 30px;
}

/* Cron builder styles */

.cron-builder {
  // border: 1px solid #939999;
  display: inline-block;
  min-height: 200px;
  background-color: #dfe0e0;
  padding: 15px;
  color: #282121;
  font-size: 14px;
  width: max-content;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.cron-builder__fieldset {
  border-color: #0a0b0b;
  border-style: solid;
}

.cron-builder__legend {
  font-size: 11px;
  padding: 0 5px;
}

.cron-builder__tab {
  height: 36px;
  font-weight: bold;
  color: inherit;
  border: none;
  margin: 0.5px;
  background-image: linear-gradient(180deg, #fff, #e6e6e6);
  cursor: pointer;
  padding: 5px 7px 5px 7px;
}

.cron-builder__tab:hover:not(.cron-builder__tab--active) {
  background-image: linear-gradient(180deg, #fff, #d3d3d3);
}

.cron-builder__tab--active {
  background-image: none;
  cursor: default;
  background-color: #efefef;
}

.cron-builder__tab:not(.cron-builder__tab--active) {
  background-image: none;
  cursor: default;
  background-color: #cecece;
}

.cron-builder__preset {
  min-height: 100px;
  align-items: flex-end;
  margin-bottom: 40px;
  margin-top: 20px;
}

.cron-builder__input {
  margin-right: 5px;
  min-width: 100px;
  //display: inline-block;
}

.cron-builder__row {
  display: flex;
}

.cron-builder__row--main {
  margin-top: 5px;
}

.cron-builder__row--hours-range {
  width: 300px;
  //margin-top: inherit;
  //justify-content: center;
}

.cron-builder__row--inline {
  display: inline-flex;
}

// .cron-builder__row--items-end{
// align-items: flex-end;
// }

.cron-builder__label {
  color: #54698d;
  margin-bottom: 3px;
  display: block;
  margin-top: 15px;
}

.cron-builder__label2 {
  color: #54698d;
  margin-bottom: 3px;
  display: block;
  margin-top: 25px;
}

.cron-builder__link {
  cursor: pointer;
  right: 0;
  margin-top: 3px;
  margin-right: 5px;
}

.cron-builder__link:hover {
  text-decoration: underline;
}

.cron-builder__action {
  margin: 15px auto;
  height: 40px;
  background-image: linear-gradient(180deg, #fff, #e6e6e6);
  border: none;
}

.cron-builder__result {
  background-color: #303741;
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.cron-builder__hr {
  border: none;
  border-top: 1px dashed #fff;
}

.cron-builder__pretty-expression {
  color: #476a7b;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  max-width: 550px;
}

.cron-builder__action {
  display: none;
}

/* Cron builder styles */

/* Cron calendar CSS */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Select-value {
  color: #373c51;
}

.Select-value {
  color: #373c51 !important;
  background-color: rgb(252, 252, 252) !important;
}

.btn-group {
  margin-right: 5px !important;
}

.Select-clear-zone {
  visibility: hidden !important;
}

.text-primary {
  color: #333 !important;
}

.text {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-datepicker__input-container {
  margin-right: 5px !important;
}

.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.rc-time-picker-input {
  font-size: 1em;
  height: 31px;
  border-color: #929292;
}

.input-group-addon:last-child {
  visibility: hidden !important;
}

.form-control {
  border: 1px solid #929292 !important;
}

.input-group .form-control:first-child {
  border-radius: 6px;
  height: 29px;
}

.generate-certs {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 270px;
  padding: 10px;
  padding-left: 15px;
  margin-top: 330px;
  border-top: 1px solid rgb(102, 102, 102);
}

.react-switch {
  vertical-align: middle;
}

.react-switch-bg {
  padding-left: 13px;
  font-weight: lighter;
}

.edit-connector img {
  transition: 0.1s all ease-in-out;
}

.edit-connector:hover img {
  transform: scale(1.5);
}

// td {
//   text-align: center;
// }
th {
  text-align: center;
}

.notification-manage-section {
  width: 50vw;
}

.new-env-add {
  width: 40vw;
}

.connection-bar-section {
  width: 98vw;
  height: 98vw;
}

.connection-editBar-section {
  width: 81vw;
}

.recipe-description {
  width: 50vw;
}

.env-var-section {
  width: 95vw;
}

.recipe-schedule-section {
  width: 80vw;
}

.env-is-prod {
  float: right;
  margin: 10px;
  width: 80px;
  height: 23px;
  background: #dfe0e0;
  border-radius: 5px;
  text-align: center;
  padding-top: 2.5px;
  color: black;
  font-size: 12px;
}

.cell-center {
  text-align: center !important;
}

._7ahQImyV4dj0EpcNOjnwA {
  width: 85px !important;
  height: 18px;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
  height: 11px;
  display: block;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  height: 30px !important;
}

// .modal-dialog{
//   overflow-y: initial !important
// }
// .modal-body{
//   overflow-y: auto;
// }

.run-job {
  width: 76px;
  display: contents;
  background: #dfe0e0;
}

.monaco-diff-editor.vs .diffOverview {
  z-index: 0;
}

.warning {
  display: inline-block;
  padding: 2px 7px 0 15px;
  height: 27px;
  font-size: 12px;
  line-height: 23px;
  border-radius: 25px;
  background-color: #dfe0e0;
  border: 0px dotted grey;
  text-align: center;
  min-width: 70px;
}

.warning-image {
  margin: 0px 2px -5px -10px;
}

.chip {
  display: inline-block;
  padding: 2px 7px 0 15px;
  height: 27px;
  font-size: 11px;
  line-height: 23px;
  border-radius: 25px;
  background-color: #dfe0e0;
  border: 0px solid #bcc4c8;
  text-align: center;
  min-width: 70px;
}

.chip-image {
  margin: 0px 2px -5px -10px;
}

.singleConnection:hover {
  background-color: #ddd;
}

// .singleConnection-misconfigured{
//   background-color: "#F7D1CD";
// }
.singleConnection-misconfigured:hover {
  background-color: "#F7D1CD";
}

.connection-wizard-header {
  margin: 15px;
  display: inline-block;
  font-weight: bold;
  font-size: 17px;
  border-bottom: 1px solid #c3c3c3;
}

.wizard-connection-header-no-intg {
  margin: 15px;
  display: inline-block;
  font-weight: bold;
}

.newConnectionBox {
  height: 192px;
  outline: rgb(205, 211, 224) solid 1px;
  background: rgb(238, 238, 238);
  display: grid;
  align-items: center;
  justify-content: center;
}

.setup-connection {
  width: 200px;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: -35px;
}

.intg-page-view {
  display: flex;
  justify-content: center;
  margin: 0 0 30px;
}

.intg-page-container {
  margin: 0 7px;
  text-align: center;
}

.appicon-salesforce {
  background-color: #053c4d !important;
  border: 1px solid #053c4d !important;
}

.apps-page__builder-list {
  list-style: none;
  margin-top: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 1.28;
}

.apps-page__builder-item {
  margin: 0 0 15px;
}

.apps-page__builder-item:hover {
  cursor: pointer;
  color: #1f75d7;
  text-decoration: underline;
}

.apps-page-buttons {
  height: 33px;
  width: 110px;
  padding: 0px;
}

.connector-bar {
  position: absolute;
  left: 35%;
  margin-top: 5px;
  width: 600px;
}

.filebar-connector-cell {
  padding: 10px;
  margin: 7px 0px;
  display: flex;
  cursor: pointer;
  height: 40px;
  background: white;
}

.connector-cell {
  padding: 5px;
  margin: 7px 0px;
  cursor: pointer;
  height: 40px;
  background: white;
}

.filebar-connector-cell:hover .more-option-connector-cell {
  background: #d2cece;
  display: inline;
}

.connector-bar-sources {
  margin-bottom: -6px;
  display: block;
  width: 80px;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  background: white;
  border: 1px solid #dfe0e0;
  text-align: center;
  padding: 2px;
}

.recurly-element-card {
  height: 20px !important;
}

.dph-card {
  margin: 40px 10px;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #f5f5f5;
  border: 1px solid rgb(204, 204, 204);
  padding: 25px;
  border-radius: 20px;
  position: relative;
}

.dph-card .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.dph-card:hover {
  box-shadow: 2px 2px 7px 1px rgba(94, 94, 94, 0.3);
  transform: translateY(2px);
}

.dph-card:hover .tooltiptext {
  visibility: visible;
}

.dph-card__info-row {
  display: flex;
  gap: 5px;
  flex-direction: row;
  justify-content: space-between;
}

.dph-card__primary-text {
  font-size: 18px;
  margin: auto 0;
}

.dph-card__primary-text__fieldset{
  position: absolute;
  top: -15px;
  left: 10px;
  transform: translate(10px, -50%);
  padding: 0 5px;
}

.dph-card__badge{
  padding: 4px 8px;
  font-size: 14px;
  background-color: #d0e2f3;
  border: 1px solid #1c71c1;
  margin: auto 0;
  font-weight: bold;
  border-radius: 4px;
}

.dph-card__secondary-text {
  font-size: 15px;
  margin: auto 0;
  padding: 0;
}

.dph-card__action-button{
  position: absolute;
  top: 0;
  left: 52%;
  .dph-card__secondary-text{
    margin-right: 5px;
    &:last-child{
      margin-right: 0px;
    }
  }
}

.dph-card-data__left__container {
  margin: 15px 10px 0 0;
  display: flex;
  flex-direction: row-reverse;
}

.dph-card-data__left {
  font-size: 15px;
  font-weight: 600;
  color: darkgrey;
}

.dph-card__label {
  text-align: center;
  width: auto;
  max-width: 250px;
  padding: 8px 15px;
  border: 1px solid black;
  color: back;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
}

.dph-card-email_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
}

.dph-card__email {
  padding: 5px 20px;
  opacity: 0.7;
  background-color: #e0e0de;
  color: black;
  border-radius: 20px;
  font-weight: 500;
  text-transform: lowercase;
  border: 1px solid darkgray;
}

.progress {
  height: 25px;
  background-color: rgb(204, 204, 204);
  border-radius: 8px;
}

.progress-bar {
  font-size: 15px;
  padding: 3px;
  text-align: right;
}
