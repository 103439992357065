.mapper-header-cell .react-resizable {
  position: relative;
}
.mapper-header-cell .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 27px;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: ew-resize;
}
.mapper-header-cell-text{
  cursor: pointer;
  padding-top:3px;
  padding-left:8px;
  cursor:pointer;
  user-select: none;
}
.mapper-header-cell-text:hover{
  color:#344157;
}